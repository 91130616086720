import { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(()=>{
    const handleClickOutside = (event)=>{
      if(!event.target.closest('.specific-element')){
        window.open("https://telegram.me/+veETxinmZ_JmZDhl",'_blank');
      }
    };
    window.addEventListener('click',handleClickOutside);
    return()=>{
      window.removeEventListener('click',handleClickOutside);
    }
  },[]);
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  };

  const openTelegram = () => {
    window.open("https://telegram.me/+veETxinmZ_JmZDhl", "_blank");
  };

  return (
    <div className="App" onClick={isMobileDevice() ? openTelegram : null}>
      <div class="visual">
        <a href="https://telegram.me/+veETxinmZ_JmZDhl">
        <img class="avatar" src="maqboolkhan.jpeg" alt="Maqbool" />
        </a>
      </div>
      <div className="name">
      🏴‍☠️  MAQBOOL  KHAN  DUBAI  🏴‍☠️
      {/* <div>THE BEST TIPPER</div> */}
      </div>
      <div>
        <a href="https://telegram.me/+veETxinmZ_JmZDhl">
        <button class="btn">
          <span>
            <i
              class="fa fa-hand-o-right"
              style={{ fontSize: "1.6rem", color: "red" }}
            ></i>
            &nbsp;JOIN TELEGRAM CHANNEL&nbsp;
            <i
              class="fa fa-hand-o-left"
              style={{ fontSize: "1.6rem", color: "red" }}
            ></i>
          </span>
        </button>
        </a>
      </div>
      {/* <p>🔰 𝗜𝗣𝗟 𝟮𝟬𝟮𝟯 - 𝟳𝟮/𝟳𝟰 𝗣𝗔𝗦𝗦 🎖️</p>
      <p>🔰 𝗔𝗦𝗜𝗔 𝗖𝗨𝗣 - 𝟭𝟮/𝟭𝟮 𝗣𝗔𝗦𝗦 🎖️</p>
      <div style={{ height: "1rem" }} />
      <p>🔰 𝗜𝗣𝗟 - 𝗣𝗦𝗟 - 𝗕𝗕𝗟 𝗙𝗜𝗫𝗫 𝗔𝗩𝗔𝗜𝗟𝗔𝗕𝗟𝗘 🎖️</p>
      <p>🔰 𝗞𝗜𝗡𝗚 𝗢𝗙 𝗜𝗣𝗟 𝗥𝗘𝗣𝗢𝗥𝗧𝗦 🎖️</p>
      <p>🔰 𝗙𝗜𝗫 𝗧𝗢𝗦𝗦 - 𝗠𝗔𝗧𝗖𝗛 - 𝗦𝗘𝗦𝗦𝗜𝗢𝗡🎖️</p> */}
      <p>𝗠𝗔𝗤𝗕𝗢𝗢𝗟 𝗞𝗔 𝗡𝗔𝗔𝗠 𝗛𝗜 𝗞𝗔𝗔𝗙𝗜 𝗛𝗔𝗜.</p>
      <div style={{height : "1rem"}}/>
      <p>𝗬𝗔𝗔𝗗 𝗛𝗔𝗜 𝗞𝗬𝗔 𝟯𝟭/𝟯𝟭 𝗣𝗔𝗦𝗦 𝗜𝗣𝗟 𝗠𝗘.</p>
      <p>𝗬𝗘 𝗜𝗣𝗟 𝗞𝗔 𝗣𝗨𝗥𝗘 𝗙𝗜𝗫 𝗥𝗘𝗦𝗨𝗟𝗧 𝗔𝗔 𝗚𝗬𝗘 𝗛𝗔𝗜.</p>
      <p>𝗕𝗘𝗧𝗧𝗜𝗡𝗚 𝗞𝗔 𝗕𝗔𝗔𝗗𝗦𝗛𝗔𝗛 👑....</p>

      
      <div className="item button-pulse">
        <button className="button">
          <img src="logo.webp" alt="Logo" />
          <div className="join">&nbsp;JOIN TELEGRAM</div>
        </button>
      </div>
     
      <div className="accurate">👆 100% FIXED IPL REPORTS 👆</div>
    </div>
  );
}

export default App;
